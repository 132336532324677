import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import { merge } from 'lodash';
import omit from 'lodash/omit';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { User } from 'vacctrack';
import useData from '../../hooks/useData';
import { Id, unwrapQuerySnapshot } from '../../services/FirestoreService';
import UsersService, { PatientSearchQuery } from '../../services/UsersService';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import PatientSearchForm from './PatientsSearchForm';

const Patients = () => {
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState<(User & Id)[]>();
  const [searchLoading, setSearchLoading] = useState(false);

  const {
    data,
    loading: _loading,
    exhaustive,
    onDataFetchStart,
    onDataFetchResult,
  } = useData<User & Id>();
  const [selectedPatient, setSelectedPatient] = useState<
    Record<string, unknown> & { user: User & Id }
  >();
  const users = searchResult ?? data;
  const loading = _loading || searchLoading;

  const loadNextPage = useCallback(
    async (lastCreated?: number) => {
      onDataFetchStart();

      const users = await UsersService.instance.listPatients(lastCreated);

      if (!users.length) {
        onDataFetchResult();
        return;
      }

      onDataFetchResult(users);
    },
    [onDataFetchResult, onDataFetchStart],
  );

  useEffect(() => {
    if (!exhaustive) {
      loadNextPage();
    }
  }, [exhaustive, loadNextPage]);

  const columns: ColDef[] = [
    {
      field: 'email',
      headerName: t('auth.email'),
      width: 300,
      sortable: false,
    },
    {
      field: 'name',
      headerName: t('shared.name'),
      width: 300,
      sortable: false,
    },
    {
      field: 'created',
      headerName: t('patient.created'),
      width: 190,
      sortable: false,
    },
  ];

  const rows = users.map((u) => ({
    id: u.id,
    email: u.email,
    name: [u.patient?.firstName, u.patient?.lastName].filter(Boolean).join(' '),
    created: i18n.dateTimeFormat(u.created),
  }));

  const onLoadMore = () => {
    loadNextPage(users[users.length - 1]?.created);
  };

  const onSearch = async ({
    email,
    firstName,
    lastName,
  }: PatientSearchQuery) => {
    if (!email && !firstName && !lastName) {
      setSearchResult(undefined);
      return;
    }

    setSearchLoading(true);

    setSearchResult(
      unwrapQuerySnapshot(
        await UsersService.instance.getBySearchQuery({
          email,
          firstName,
          lastName,
        }),
      ),
    );

    setSearchLoading(false);
  };

  const onEdit = (updatedSrc: User & Id) => {
    return UsersService.instance.doc(updatedSrc.id).set(
      {
        patient: updatedSrc.patient,
      },
      { merge: true },
    );
  };

  const onRowClick = async (r: RowModel) => {
    const user = users.find((d) => d.id === r.id);

    if (!user) {
      return;
    }

    const {
      certificates,
      reports,
      reminders,
    } = await UsersService.instance.getUserServiceItems(user!.id);

    setSelectedPatient({
      certificates: certificates.map((c) =>
        merge(
          {
            ...omit(c, ['id', 'releases', 'patient', 'userId']),
            created: i18n.dateTimeFormat(c.created),
            serviceDate: i18n.dateTimeFormat(c.serviceDate),
          },
          {
            product: {
              expiryDate: i18n.dateTimeFormat(c.product.expiryDate, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }),
            },
          },
        ),
      ),
      selfReports: reports.map((c) => ({
        ...omit(c, ['id', 'userId']),
        created: i18n.dateTimeFormat(c.created),
        serviceDate: i18n.dateTimeFormat(c.serviceDate),
      })),
      reminders: reminders.map((c) => ({
        ...omit(c, ['id', 'userId', 'subject']),
        created: i18n.dateTimeFormat(c.created),
        reminderDate: i18n.dateTimeFormat(c.reminderDate),
      })),
      user,
    });
  };

  return (
    <>
      <PatientSearchForm {...{ onSearch, loading }} />
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onLoadMore={onLoadMore}
        onCellClick={(u) => onRowClick(u.row)}
        exhaustive={exhaustive}
      />
      <Dialog
        onClose={() => setSelectedPatient(undefined)}
        open={!!selectedPatient}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          {selectedPatient &&
            (() => {
              const { user, ...rest } = selectedPatient;
              return (
                <>
                  <ReactJson
                    src={rest}
                    name={false}
                    collapsed={2}
                    displayDataTypes={false}
                    quotesOnKeys={false}
                    enableClipboard={false}
                    // @ts-ignore
                    displayArrayKey={false}
                    sortKeys
                  />
                  <br />
                  <Typography variant="body2">{t('patient.editInfo')}</Typography>
                  <ReactJson
                    src={user}
                    name={false}
                    displayDataTypes={false}
                    quotesOnKeys={false}
                    enableClipboard={false}
                    // @ts-ignore
                    displayArrayKey={false}
                    sortKeys
                    onEdit={(pr) => {
                      if (pr.namespace[0] !== 'patient') {
                        return false;
                      }

                      console.log('asdfdsaf')

                      onEdit(pr.updated_src as User & Id);

                      return true;
                    }}
                  />
                </>
              );
            })()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Patients;
