import firebase from 'firebase/app';
import { Certificate, Reminder, Report, User } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService, { Id, typeConverter, unwrapQuerySnapshot } from './FirestoreService';

export interface PatientSearchQuery {
  email: string;
  firstName: string;
  lastName: string;
}

export default class UsersService extends FirestoreService<User> {
  static instance = new UsersService();

  constructor() {
    super('users');
  }

  async listMsps(lastVerifiedTimestamp?: number) {
    let query = this.collection()
      .where('msp.verifiedTimestamp', '>', 0)
      .orderBy('msp.verifiedTimestamp', 'desc')
      .limit(PAGE_SIZE);

    if (lastVerifiedTimestamp) {
      query = query.startAfter(lastVerifiedTimestamp);
    }

    return unwrapQuerySnapshot(await query.get());
  }

  async listPatients(lastCreated?: number) {
    let query = this.collection()
      .where('type', '==', 'patient')
      .orderBy('created', 'desc')
      .limit(PAGE_SIZE);

    if (lastCreated) {
      query = query.startAfter(lastCreated);
    }

    return unwrapQuerySnapshot(await query.get());
  }

  async getBySearchQuery({
    email,
    firstName,
    lastName,
  }: PatientSearchQuery) {
    let query = this.collection() as firebase.firestore.Query<User & Id>;

    if (email) {
      query = query.where('email', '==', email);
    }

    if (firstName) {
      query = query.where('patient.firstName', '==', firstName);
    }

    if (lastName) {
      query = query.where('patient.lastName', '==', lastName);
    }

    return query.get();
  }

  async getUserServiceItems(userId: string) {
    const userDoc = this.doc(userId);

    const [certificatesSn, reportsSn, remindersSn] = await Promise.all([
      userDoc
        .collection('certificates')
        .withConverter(typeConverter<Certificate>())
        .get(),
      userDoc
        .collection('reports')
        .withConverter(typeConverter<Report>())
        .get(),
      userDoc
        .collection('reminders')
        .withConverter(typeConverter<Reminder>())
        .get(),
    ]);

    return {
      certificates: unwrapQuerySnapshot(certificatesSn),
      reports: unwrapQuerySnapshot(reportsSn),
      reminders: unwrapQuerySnapshot(remindersSn),
    };
  }
}
