import { useCallback, useMemo, useState } from 'react';
import { PAGE_SIZE } from '../util/constants';

export default function useData<T>(pageSize = PAGE_SIZE) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [exhaustive, setExhaustive] = useState(false);

  const onDataFetchStart = useCallback(() => {
    setLoading(true);
  }, []);

  const onDataFetchResult = useCallback(
    (d?: T[], replace = false) => {
      setLoading(false);

      if (!d) {
        setExhaustive(true);
        return;
      }

      if (d.length < pageSize) {
        setExhaustive(true);
      }

      if (replace) {
        setData(d);
        return;
      }

      setData((v) => [...v, ...d]);
    },
    [pageSize],
  );

  return useMemo(
    () => ({
      data,
      loading,
      exhaustive,
      setLoading,
      onDataFetchStart,
      onDataFetchResult,
    }),
    [data, exhaustive, loading, onDataFetchResult, onDataFetchStart],
  );
}
