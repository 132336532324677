import { OutlinedInput } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientSearchQuery } from '../../services/UsersService';
import VTForm from '../ui/VTForm';

interface Props {
  onSearch: (query: PatientSearchQuery) => void;
  loading: boolean;
}

const PatientSearchForm = ({ onSearch, loading }: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <VTForm
      onSubmit={() => onSearch({ email, firstName, lastName })}
      submitText={t('shared.search')}
      loading={loading}
      row
    >
      <OutlinedInput
        type="email"
        placeholder={t('shared.email')}
        value={email}
        margin="dense"
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <OutlinedInput
        placeholder={t('shared.firstName')}
        value={firstName}
        margin="dense"
        onChange={(e) => setFirstName(e.currentTarget.value)}
      />
      <OutlinedInput
        placeholder={t('shared.lastName')}
        value={lastName}
        margin="dense"
        onChange={(e) => setLastName(e.currentTarget.value)}
      />
    </VTForm>
  );
};

export default PatientSearchForm;
