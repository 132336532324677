import { Product } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService, { unwrapQuerySnapshot } from './FirestoreService';

export default class ProductsService extends FirestoreService<Product> {
  static instance = new ProductsService();
  constructor() {
    super('products');
  }

  create(data: Product) {
    return this.collection().add({
      id: '',
      ...data,
    });
  }

  async list(lastId?: string) {
    let query = this.collection().orderBy('name').limit(PAGE_SIZE);

    if (lastId) {
      query = query.startAfter(lastId);
    }

    return unwrapQuerySnapshot(await query.get());
  }
}
