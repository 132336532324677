import { Box, Button } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'vacctrack';
import useData from '../../hooks/useData';
import { Id } from '../../services/FirestoreService';
import UsersService from '../../services/UsersService';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import ManualVerifyForm from './ManualVerifyForm';

const Msps = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const {
    data,
    loading,
    exhaustive,
    onDataFetchStart,
    onDataFetchResult,
  } = useData<User & Id>();

  const loadNextPage = useCallback(
    async (lastVerifiedTimestamp?: number) => {
      onDataFetchStart();

      const users = await UsersService.instance.listMsps(lastVerifiedTimestamp);

      if (!users.length) {
        onDataFetchResult();
        return;
      }

      onDataFetchResult(users);
    },
    [onDataFetchResult, onDataFetchStart],
  );

  useEffect(() => {
    if (!exhaustive) {
      loadNextPage();
    }
  }, [exhaustive, loadNextPage]);

  const columns: ColDef[] = [
    {
      field: 'email',
      headerName: t('auth.email'),
      width: 220,
      sortable: false,
    },
    {
      field: 'name',
      headerName: t('shared.name'),
      width: 320,
      sortable: false,
    },
    {
      field: 'npi',
      headerName: t('msps.npi'),
      width: 120,
      sortable: false,
    },
    {
      field: 'verified',
      headerName: t('msps.verifiedOn'),
      width: 190,
      sortable: false,
    },
  ];

  const rows = data.map((u) => ({
    id: u.id,
    email: u.email,
    name: u.msp?.npiInfo.basic.name,
    npi: u.msp?.npiInfo.number,
    verified: i18n.dateTimeFormat(u.msp?.verifiedTimestamp),
  }));

  const onSelect = (r: RowModel) => {
    console.log(r);
  };

  const onLoadMore = () => {
    loadNextPage(data[data.length - 1]?.msp?.verifiedTimestamp);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        {showForm ? (
          <ManualVerifyForm onSuccess={() => setShowForm(false)} />
        ) : (
          <Button variant="outlined" onClick={() => setShowForm(true)}>
            {t('msps.confirmManually')}
          </Button>
        )}
      </Box>
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onRowClick={(r) => onSelect(r.row)}
        onLoadMore={onLoadMore}
        exhaustive={exhaustive}
      />
    </>
  );
};

export default Msps;
