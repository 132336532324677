import { Dialog, DialogContent } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import stableStringify from 'json-stable-stringify';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Issue, Product } from 'vacctrack';
import useData from '../../hooks/useData';
import { Id } from '../../services/FirestoreService';
import IssuesService from '../../services/IssuesService';
import ProductsService from '../../services/ProductsService';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';

const VaccWatch = () => {
  const { t } = useTranslation();
  const {
    data,
    loading,
    exhaustive,
    onDataFetchStart,
    onDataFetchResult,
  } = useData<Issue & Id>();
  const [productsById, setProductsById] = useState<Dictionary<Product & Id>>();
  const [selectedIssue, setSelectedIssue] = useState<Issue & Id>();

  const loadNextPage = useCallback(
    async (lastId?: string, replace = false) => {
      onDataFetchStart();

      const issue = await IssuesService.instance.list(
        replace ? undefined : lastId,
      );

      if (!issue.length) {
        onDataFetchResult();
        return;
      }

      onDataFetchResult(issue, replace);
    },
    [onDataFetchResult, onDataFetchStart],
  );

  useEffect(() => {
    (async () => {
      setProductsById(keyBy(await ProductsService.instance.list(), 'id'));
    })();
  }, []);

  useEffect(() => {
    if (!exhaustive) {
      loadNextPage();
    }
  }, [exhaustive, loadNextPage]);

  const columns: ColDef[] = [
    {
      field: 'created',
      headerName: t('shared.created'),
      width: 180,
      sortable: false,
    },
    {
      field: 'diseaseName',
      headerName: t('products.name'),
      width: 180,
      sortable: false,
    },
    {
      field: 'product',
      headerName: t('products.brand'),
      width: 320,
      sortable: false,
    },
    {
      field: 'effects',
      headerName: t('vaccwatch.effects'),
      width: 320,
      sortable: false,
    },
  ];

  const rows = productsById
    ? data.map((is) => {
        const product = productsById[is.productId];

        return {
          ...pick(is, [
            'ageGroup',
            'bloodType',
            'comment',
            'ethnicity',
            'preexistingConditions',
            'productExpiryDate',
            'productLotNumber',
            'race',
            'shotNumber',
            'source',
            'id',
          ]),
          created: i18n.dateTimeFormat(is.created),
          serviceDate: i18n.dateTimeFormat(is.serviceDate),
          diseaseName: product.name,
          product: `${product.brand} – ${product.manufacturer}`,
          effects: is.effects.join(', ').replace(/-/g, ' '),
          effectsOther: is.otherEffect,
        };
      })
    : [];

  const onSelect = (r: RowModel) => setSelectedIssue(r as Issue & Id);

  const onLoadMore = () => {
    loadNextPage(data[data.length - 1]?.id);
  };

  return (
    <>
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onRowClick={(r) => {
          onSelect(r.row);
        }}
        onLoadMore={onLoadMore}
        exhaustive={exhaustive}
      />
      <Dialog
        onClose={() => setSelectedIssue(undefined)}
        open={!!selectedIssue}
      >
        <DialogContent>
          {selectedIssue && (
            <pre>
              {stableStringify(selectedIssue, {
                space: 2,
              })}
            </pre>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VaccWatch;
