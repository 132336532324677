import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends DataGridProps {
  exhaustive: boolean;
  onLoadMore: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const VTDataGrid = ({ onLoadMore, exhaustive, ...props }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DataGrid
      className={classes.root}
      autoHeight
      rowsPerPageOptions={[]}
      disableSelectionOnClick
      components={{
        pagination: () =>
          exhaustive ? null : (
            <Box sx={{ marginRight: 1 }}>
              <Button onClick={onLoadMore}>{t('shared.loadMore')}</Button>
            </Box>
          ),
      }}
      hideFooter={exhaustive}
      {...props}
    />
  );
};

export default VTDataGrid;
