import { Box, Button } from '@material-ui/core';
import { ColDef, RowModel } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Broadcast } from 'vacctrack';
import useData from '../../hooks/useData';
import BroadcastsService from '../../services/BroadcastsService';
import { Id } from '../../services/FirestoreService';
import i18n from '../../util/i18n';
import VTDataGrid from '../ui/VTDataGrid';
import CreateForm from './CreateForm';

const Broadcasts = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const {
    data,
    loading,
    exhaustive,
    onDataFetchStart,
    onDataFetchResult,
  } = useData<Broadcast & Id>();

  const loadNextPage = useCallback(
    async (lastVerifiedTimestamp?: number, replace = false) => {
      onDataFetchStart();

      const broadcasts = await BroadcastsService.instance.list(
        lastVerifiedTimestamp,
      );

      if (!broadcasts.length) {
        onDataFetchResult();
        return;
      }

      onDataFetchResult(broadcasts, replace);
    },
    [onDataFetchResult, onDataFetchStart],
  );

  useEffect(() => {
    if (!exhaustive) {
      loadNextPage();
    }
  }, [exhaustive, loadNextPage]);

  const columns: ColDef[] = [
    {
      field: 'title',
      headerName: t('shared.title'),
      width: 320,
      sortable: false,
    },
    {
      field: 'created',
      headerName: t('shared.created'),
      width: 190,
      sortable: false,
    },
  ];

  const rows = data.map((b) => ({
    id: b.id,
    title: b.title,
    created: i18n.dateTimeFormat(b.created),
  }));

  const onSelect = async (r: RowModel) => {
    if (!window.confirm(t('broadcasts.deleteConfirm'))) {
      return;
    }

    await BroadcastsService.instance.doc((r as Broadcast & Id).id).delete();

    loadNextPage(undefined, true);
  };

  const onLoadMore = () => {
    loadNextPage(data[data.length - 1]?.created);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        {showForm ? (
          <CreateForm
            onSuccess={() => {
              loadNextPage(undefined, true);
              setShowForm(false);
            }}
          />
        ) : (
          <Button variant="outlined" onClick={() => setShowForm(true)}>
            {t('broadcasts.create')}…
          </Button>
        )}
      </Box>
      <VTDataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        onRowClick={(r) => onSelect(r.row)}
        onLoadMore={onLoadMore}
        exhaustive={exhaustive}
      />
    </>
  );
};

export default Broadcasts;
