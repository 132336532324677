import { Broadcast } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService, { unwrapQuerySnapshot } from './FirestoreService';

export default class BroadcastsService extends FirestoreService<Broadcast> {
  static instance = new BroadcastsService();
  constructor() {
    super('broadcasts');
  }

  async list(lastCreated?: number) {
    let query = this.collection().orderBy('created', 'desc').limit(PAGE_SIZE);

    if (lastCreated) {
      query = query.startAfter(lastCreated);
    }

    return unwrapQuerySnapshot(await query.get());
  }
}
