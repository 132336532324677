import { Issue } from 'vacctrack';
import { PAGE_SIZE } from '../util/constants';
import FirestoreService, { unwrapQuerySnapshot } from './FirestoreService';

export default class IssuesService extends FirestoreService<Issue> {
  static instance = new IssuesService();

  constructor() {
    super('issues');
  }

  async list(lastId?: string) {
    let query = this.collection().orderBy('created', 'desc').limit(PAGE_SIZE);

    if (lastId) {
      query = query.startAfter(lastId);
    }

    return unwrapQuerySnapshot(await query.get());
  }
}
